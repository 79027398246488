import { File } from 'features/common/types'

import CONFIG from '../config'

export const API_URL = CONFIG.apiUrl

export const VERIFY_URL = CONFIG.verifyUrl

export const SEARCH_PAGE = CONFIG.searchPage

export const HOME_IMAGE: File = {
  url: '/home.jpg',
  formatted: {
    sm: '/home-sm.jpg',
    md: '/home-md.jpg',
    lg: '/home-lg.jpg',
  },
}

export const FAQ_ITEMS = [
  {
    question: 'What is Watersports Everywhere?',
    answer:
      'We are a provider of the cheapest prices for any water activities equipment rental as well as bookings of all types of water activities and water sports lessons, courses, tours etc. We give global access, to ensure your needs are met.',
  },
  {
    question: 'Why do people book with Watersports Everywhere?',
    answer:
      'With Watersports Everywhere you can select the cheapest prices for your preferred water item rental/activity. You have the freedom to browse and select a water activities provider in your selected location at the desired date and time. All done online in one place.',
  },
  {
    question: 'Who can use the Watersports Everywhere?',
    answer:
      'Everyone can make a booking and enjoy water activities thanks to Watersports Everywhere. Please note, only adults should create an account and bookings.',
  },
  {
    question:
      'Do I need to provide any additional documents for water sports that require a license?',
    answer:
      'During the booking process you do not need to provide any additional information, yet be informed that the water activity provider may request presenting a license before the equipment rental. This information shall be included in your booking disclaimer.',
  },
]

export const PRIVACY_POLICY_ITEMS = [
  {
    title: 'General',
    content: `
            The Watersports Everywhere Application allows booking of water sport item rental and booking of water sport activities.<br/><br/>
            The Watersports Everywhere Privacy Policy explains to you in detail the information we collect and how we use that information. The Watersports Everywhere Privacy Policy is used to inform application users regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service. The Watersports Everywhere Service takes its user privacy very seriously and will only use user’s information in accordance with this Privacy Policy. Watersports Everywhere won't sell or rent any user personally identifiable information to third parties. Watersports Everywhere will hold and transmit user's personal information in a safe, confidential, and secure environment.<br/><br/>
            While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you for verification purposes in the case that you forget your password.<br/><br/>
            If you have questions or concerns regarding this Privacy Policy, please contact our support service at <a href='mailto:privacy@watersports-everywhere.com'>privacy@watersports-everywhere.com</a> before using the service. Acceptance of the Privacy Policy and the Terms of Service implies your consent to our use and disclosure of your personal information using the methods described in this Privacy Policy.<br/><br/>
            If you do not want your personal information to be used and saved as described in this Privacy Policy, please do not register for the Watersports Everywhere service.<br/><br/>
            In contrast, the service can be acquired without saving the personal data, consequently, the information provided would be saved internally and can not be recovered from our systems.
        `,
  },
  {
    title: 'Collected Information',
    content: `
            Watersports Everywhere collects your personal information as a user in order to provide you with the best service possible, including but not limited to:<br/><br/>
            <ol style='list-style: disc'>
                <li>Register and manage your Watersports Everywhere account;</li>
                <li>Provide the service (i.e. Watersports Everywhere for your water sports booking), service support, and customize the service to your requirements, ownership of transactional data;</li>
                <li>Grant the service, service alerts (including flight alerts) or promotional materials via email, mobile and web notifications and</li>
                <li>Provide and bill you for services and subscriptions that have been required and ordered by you.</li>
            </ol>
            To provide the Watersports Everywhere service, we might request the following information:<br/><br/>
            <ol style='list-style: disc'>
                <li>Personal Data; (e.g. your name, last name, postal address, email address, and mobile or other telephone numbers password)</li>
                <li>Account details: birth date, nationality, gender, address, email address, mobile number.</li>
                <li>Payment information such as transaction identifiers and summary information that does not include credit card or bank account numbers (we do not collect or store financial account information). (cfr. Section 5 "Payments").<li>
                <li>Web behavior information such as information related to how the users use the Products (e.g. browser type, domains, page views) collected through cookies and other automated technology. (cfr. Section 7 "Cookies").</li>
            </ol>
        `,
  },
  {
    title: 'Personal Data Usage',
    content: `
            Privacy is a serious concern for us. Watersports Everywhere (i) collects, (ii) uses, (iii) maintains, and (iv) may share your Personal Data provided by you or collected by us with its affiliates, parent companies, or other related companies for all purposes necessary to ensure the proper functioning and operation of the user accounts and/or the proper functioning of the Products and Services.<br/><br/>
            These purposes may include (collectively the "Purpose"):<br/><br/>
            <ol style='list-style: disc'>
                <li>Creating and managing your Account to use the Application;</li>
                <li>Providing information and allowing the Users access to the Service;</li>
                <li>Diagnosing technical problems and managing technical support and processing inquiries concerning the Service;</li>
                <li>Contacting the Users by phone, email, text message, or push notifications (if they are enabled) to (i) verify your account (ii) for information and operational purposes such as account management, instructions, alerts, reminders, customer service, system maintenance, and others.</li>
                <li>Commercializing the Application.</li>
            </ol>
            The operation, evaluation, and improvement of the Service (including improving the current Service, analysis of our Application, quality control activities and performing internal business functions such as accounting and auditing);* Protect identify and prevent fraud and other unlawful activities, claims and other liabilities;<br/><br/>
            <ol style='list-style: disc'>
                <li>Complying with and enforcing any applicable legal obligations with respect to our Terms of Service and Privacy Policy.</li>
            </ol>
            Furthermore, you agree that Watersports Everywhere is free to access, retain, and disclose Personal Data in the following events:<br/><br/>
            <ol style='list-style: disc'>
                <li>In order to comply with any applicable legislation or regulations;</li>
                <li>If a law enforcement authority or other government official requests so;</li>
                <li>To enforce the Watersports Everywhere Terms of service or Privacy Policy</li>
                <li>The investigation of any suspected or actual fraudulent or illegal activity and</li>
                <li>To protect your or our safety and/or rights.</li>
            </ol>
            By using the Application, you acknowledge and explicitly agree that we may disclose certain information to vendors and service providers who help us provide the Service. We will not share your information without your permission, although the user can restore the account upon request.
        `,
  },
  {
    title: 'Information Security Measures',
    content: `
            Watersports Everywhere is committed to treating your information with high standards. Watersports Everywhere shall take appropriate administrative, technical, and organizational measures against unauthorized or unlawful processing of any Personal Data or its accidental loss, destruction or damage, access, disclosure, or use.<br/><br/>
            Upon written request, Watersports Everywhere can provide you with a list of entities that may have access to your Personal Data. If such a case, these entities might have entered into confidentiality agreements prior to having been granted access to your Personal Data. Our Sub-processors include Amazon for web-services & storage, Apple for the apple wallet, storing, app, etc. Google for the android version of the app and Slack related to Payments.<br/><br/>
            When providing sensitive information (such as a credit card number) ABBM will encrypt the transmission of such information using secure socket layer technology (SSL). ABBM follows generally accepted standards to protect the personal information submitted to us, both during transmission and upon receipt. Please note that no method of transmission over the Internet, or method of electronic storage, is 100% secure, therefore, we cannot guarantee its absolute security.<br/><br/>
            In the event of and following discovery or notification of a breach of the security of the Personal Data, or access by an unauthorized person, Watersports Everywhere is required by law to notify the user if the breach is likely to affect your privacy.<br/><br/>
            These Services do not address anyone under the age of 18. We do not purposely collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.<br/><br/>
            Please, do not share your Watersports Everywhere identification or password information with anyone. If you share your identification or password information you will be liable for any activity related to your information.<br/><br/>
            If there is any doubt that your identification or password information is compromised, please log in to Watersports Everywhere and change your password. You should never email any sensitive information such as your password, credit card, or bank account information. Watersports Everywhere will never request you to send your password to us via email. We also recommend you keep your operating system and browser version up-to-date with the latest security patches and actualizations which will help to ensure the protection of your information.<br/><br/>
            Watersports Everywhere assumes no liability whatsoever for any loss or degradation of such information stored on its systems or any direct or indirect damages arising out of such loss or degradation. Through the use of the Watersports Everywhere software application, the Licensee may affect the storage of the licensee's own personal data in the Watersports Everywhere servers. Nothing contained in this section 4 shall be construed to derogate from Watersports Everywhere rights to use your data to collect Watersports Everywhere software application users behavior and for statistical purposes, to improve our service, and forward this information to third parties (i.e. airlines) with your prior consent, so as to help those third parties to improve their product and ancillary services.
        `,
  },
  {
    title: 'Payments',
    content: `
            The Watersports Everywhere application has two different payment methods. (i)The payment by Credit card is free of charge and the Transaction is safe and certified thanks to the use of advanced technologies that allow our payment gateway to anonymously manage and secure your sensitive data. The user can pay with the main credit cards available on the market(Visa, Mastercard, American Express). Financial information will be collected, such as credit card number, expiration date, and the digit security code. All transactions shall be cleared via a leading provider of payment services, and transmission of your credit card information and transactions are secured with SSL standards. (ii) The payments transferred by the digital platform is the most efficient and secure method for users to perform online payments. If the user selects this payment method, only the login credentials can be required in order to proceed with the payment in a few seconds through their account. All the information will be handled entirely by the digital platform, ensuring the protection of your purchases and your data. For the protection of your security, Watersports Everywhere does not process or store any information about payment instruments (e.g. credit card numbers), which are processed by the respective payment service providers ( Stripe ) transactional data. The actual payment is made after transferring the user to a protected and encrypted page of the banking service. Only after the transaction has taken place, the bank service provider informs Watersports Everywhere of the outcome of the payment, without providing any information regarding the credit card used. For this reason, Watersports Everywhere has no power over any refusal of the credit card used for payment. Watersports Everywhere shall not be held liable in any way for direct or indirect consequences resulting from the use of the credit card by the user to make payment for the products and/or services purchased.
        `,
  },
  {
    title: 'Geolocation',
    content: `
            Watersports Everywhere will obtain your location through the mobile device or directly from you and will be used to provide you with information or services needed or requested by the user. We do not transfer the identity or personally identifiable information of our users to third parties without prior notification and consent, except for providing the desired service.
        `,
  },
  {
    title: '”Cookies” use',
    content: `
            When using the Application, we may collect certain information by automated means, such as cookies (small text files stored in your browser) and similar technologies, including the mobile application identifiers, to improve your experience of the Service, increase security, measure use, and effectiveness, identify and resolve issues and marketing purposes.<br/><br/>
            The information we collect in this manner includes IP address, browser characteristics, device characteristics, dates and times of visiting, operating system version, information on actions taken on our Services (such as usage, activity).<br/><br/>
            You can control the cookies through your browser settings and other tools. Your device may offer you control over the use of cookies or other technologies when you use the Application.<br/><br/>
            For example, you may be able to set your device or browser to disable, clear, reset, or block the use of cookies or similar technologies. Please consider, that without cookies the services may not work properly or may not be able to fully work its features by the user.<br/><br/>
            By continuing to use our Service, you consent to the placement of cookies and other automated means in your browser and device in accordance with this <a href='/privacy-policy'>Privacy Policy</a>.
        `,
  },
  {
    title: 'Disclosure to Third Parties',
    content: `
            In the event of a full or partial merger with, or acquisition of all or part of Watersports Everywhere, we may transfer your Personal Data to a Third Party. In such an event, Watersports Everywhere shall impose this Third Party to use any Personal Data strictly consistent with this Privacy Policy.<br/><br/>
            Other than as set out in this Privacy Policy, we shall not sell or otherwise disclose your Personal Data to third parties without obtaining your prior explicit consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.<br/><br/>
            We may also share Personal Data with Third-Party service providers that help us to provide, understand, commercialize, and improve our Products. We do not authorize these Third-Party service providers to use or disclose your Personal Data except if strictly necessary, to perform any services under our supervision or to comply with applicable legislation. We seek to provide any such Third Party service provider with only the Personal Data they need to perform their specific function.<br/><br/>
            In any event, such third party service providers shall be obliged to treat your Personal Data in accordance with this privacy policy. However, Watersports Everywhere cannot be held liable for any damages, whether direct or indirect, that may result from the misuse of your Personal Data by such Third-Party service providers.
        `,
  },
  {
    title: 'Third Party websites or applications',
    content: `
            You acknowledge and agree that certain content or service provided by third parties may be made available to you through the services. You agree that such linked content or services may have their own privacy policies for which Watersports Everywhere cannot be held responsible. Watersports Everywhere does not in any way review or endorse the privacy practices of such third parties.
        `,
  },
  {
    title: 'Updates or changes to our Privacy Policy',
    content: `
            The Watersports Everywhere Privacy Policy may be modified or adjusted as the service evolves and changes. If the methods we use to store and disclose your information changes, you will have the choice as to whether you want to participate in new practices or not, in either case we will notify each user prior to any changes. The Latest version of the Privacy Policy and amendments will be available in the Watersports Everywhere software application and website for your convenience.
        `,
  },
  {
    title: 'Your rights',
    content: `
            Right of access. If you are concerned or have any questions about your Personal data, you have the right to request access to the personal data which we hold or process about you. We will then provide you with information about the data being processed and on the source of the data.<br/><br/>
            Right of rectification and right of erasure. You have the right to request us free of charge to correct, erase, or block any inaccuracies in your Personal Data if such Personal Data would be incomplete, inaccurate, or processed unlawfully.<br/><br/>
            In case of inquiries, questions, or concerns about our Privacy Policy or our practices, you may email us at <a href='mailto:privacy@watersports-everywhere.com'>privacy@watersports-everywhere.com</a>.
        `,
  },
]

export const TERMS_CONDITIONS_ITEMS = [
  {
    title: 'Introduction',
    content: `
            Please read these terms of service carefully before using the Watersports Everywhere app operated by The Company.<br/><br/>
            The user’s access to and use of the Watersports Everywhere services is conditioned on the user’s acceptance and compliance with these Terms. The following Terms apply to all visitors, users, legal or natural persons who access or use the Service. By accessing or using Watersports Everywhere services, the user agrees to be bound by these Terms. If the user disagrees with any part of these Terms, then the user shall not access this service.<br/><br/>
            The Company reserves the right, in their sole discretion, to make changes or modifications to these Terms of service at any time and for any reason. The users shall be alerted about any changes by updating the “Last updated” date of these Terms of Service and the user waives any right to receive specific notice of each such change. It is the user’s responsibility to periodically review these Terms of Service to stay informed of updates. The user shall be subject to, and shall be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Service by the continued use of the website or the app after the date such revised Terms are posted.<br/><br/>
            <b>Modification of the Terms of Use</b><br/>
            ADVANCED BOAT BOOKING AND MORE LTD. Software applications are a work in progress, therefore ABBM reserves the right to replace or modify, even dramatically, the ADVANCED BOAT BOOKING AND MORE LTD. Terms of Use at any time and at its sole discretion. The date of the last update of the ADVANCED BOAT BOOKING AND MORE LTD. Terms of Use will be indicated at the top of this document. Any changes will be effective immediately upon posting of the revised version. It is the user’s responsibility to regularly review the Terms of Use to check if new changes have been included. Your continued use of the Service following the posting of any changes to the Terms of Use will constitute your acceptance of any such changes. If you do not agree with the included changes, you must stop using the Service. Your failure to abide by the ADVANCED BOAT BOOKING AND MORE LTD. Terms of Use or any further terms or conditions regulating the Service may result in suspension or termination of your access to the Service, without notice, in addition to any other remedies available to ABBM.<br/><br/>
            <b>Account Information and Data</b><br/>
            You may register with ADVANCED BOAT BOOKING AND MORE LTD. to create an account (“Account”). You shall select a password and receive an account upon completing the registration process. The use of Accounts is subject to this Agreement. ABBM does not own any data, information, or material that you submit to the Service in the course of using the Service (" Customer Data "). The User and not ABBM shall be solely and exclusively responsible for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use all Customer Data, and ABBM shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Customer Data. ABBM reserves the right to withhold, remove and/or discard Customer Data without notice for any breach, including, without limitation, your non-payment. Upon termination for cause, your right to access or use Customer Data immediately ceases, and ABBM shall have no obligation to maintain or forward any Customer Data.
        `,
  },
  {
    title: 'ABBM Responsibilities',
    content: `
            The Service includes access to ADVANCED BOAT BOOKING AND MORE LTD. software application and all its functionalities, accessible through a Smartphone, PDA or computer, or any other platform or device where the software is executable. The Watersports Everywhere warrants that it shall perform its obligations under this Agreement and the Privacy Policy at all times: (i) on a professional basis in accordance with the highest standards in its industry, (ii) in accordance with the relevant Applicable Law and regulations.<br/><br/>
            The Company shall not be held responsible by the User for any content published by any third party hereto.
        `,
  },
  {
    title: 'User Responsibilities',
    content: `
            It is the user’s responsibility to ensure there is adequate time for the water sport item pick-up, lesson, course. It is also the user’s responsibility to familiarize with the booking disclaimer, which includes restrictions to selected item/activity.<br/><br/>
            You are solely and exclusively responsible for all activity occurring under your use of the account and shall abide by all software application local, state, national and foreign laws, treaties, and regulations in connection with your use of the software application and its services, including those related to data privacy, international communications and the transmission of technical or personal data. You shall: (i) notify ABBM immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to ABBM immediately and use reasonable efforts to stop immediately any copying or distribution of content that is known or suspected by you; and (iii) not impersonate another ABBM user or provide false identity information to gain access to or use the software application.<br/><br/>
            The user’s booking is made on a pre-booked time slot allocation. Therefore, it is the user’s responsibility to ensure timely arrival within the booked time. If for any reason the user misses the allocated time slot, The Company will endeavor to ensure access to Watersports Everywhere, however, this cannot be guaranteed.<br/><br/>
            The user must bring confirmation of the booking in order to use Watersports Everywhere booked services.<br/><br/>
            The user must not resell or transfer any booking (in whole or in part).<br/><br/>
            As part of the booking process the user will need to submit the following details:<br/>
            - Select the date and time slot that the user wishes to use the Watersports Everywhere booked services,<br/>
            - Select the location, and the number of water sports items wished to book.<br/>
            - After the payment has been recorded and the credit card has an authorization hold (see cfr. Section 5 "Fee and Payments"), the user has to introduce the names of each person that will use the Watersports Everywhere booking.<br/>
            <br/>
            The user has to take the time to read and check the order during the booking process. After this, it will be requested to submit payment information to confirm the order.
        `,
  },
  {
    title: 'Conditions of Use of the Services and Content',
    content: `
            The services are provided under the following Terms of Service, Watersports Everywhere will provide to the user an app that will allow them to book in advance, as well as book for immediate usage of Watersports Everywhere booking.<br/><br/>
            The booking is only valid for the specific date and time stated on the booking confirmation. The booking is not transferable to another person once it has been issued.Bookings can be made by adults only. The cardholder’s permission must be granted if the user is underage.<br/><br/>
            The price paid by the user is the price accepted and confirmed to the purchaser via the Watersports Everywhere app. All prices are in the currency the User might have selected by default or by their own selection and include Value Added Tax (VAT) and any other applicable taxes. The booking shall be valid for a single use of Watersports Everywhere service.<br/><br/>
            As part of the booking process the purchaser shall provide the following details: select the desired date and time slot to use the Watersports Everywhere booked services and the quantity of Watersports Everywhere items/activities the purchaser wishes to purchase. Please take the time to read and check the order at each page of the booking process. The purchaser shall then be asked to submit their payment method details and to confirm the booking.<br/><br/>
            <b>Prohibited Uses</b><br/>
            You may use the software application only for your personal and internal purpose and shall not: (i) interfere with or disrupt the integrity or performance of the software application or the data contained therein or intercept, monitor, damage or modify any communication which is not intended for you; or (ii) attempt to gain unauthorized access to the software application or its related systems or networks; (iii) send spam or otherwise duplicative or unsolicited messages in violation of software application laws; (iv) send or store infringing, obscene, threatening, libelous, or otherwise unlawful material, including material harmful to children or of any third party privacy rights; (v) send or store material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs that are designed to distort, delete, damage or disassemble the ADVANCED BOAT BOOKING AND MORE LTD. software application or communication. You may not undertake, cause, permit or authorize to "frame" or "mirror" any content of the ADVANCED BOAT BOOKING AND MORE LTD. software application on any other server or wireless or Internet-based device copy, display, distribute, perform, publish, modify, create (derivative) works, translate, reverse engineering, decompile, disassemble or hack the ADVANCED BOAT BOOKING AND MORE LTD. software application and/or ADVANCED BOAT BOOKING AND MORE LTD. services or any part thereof, in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the Service.<br/><br/>
            To use any of our services or to register for an account, the user who pays for the service must be an adult and agree to our terms. When creating an account with the company, the user is responsible for the account, its security (eg, not to share credentials, etc.), and all the activity on it.<br/><br/>
            The Company provides different methods through which the user may interact with our services.<br/><br/>
            The user agrees to our Privacy Policy. Any password will be encrypted and is confidential and shall only be known to the user. The security of the password shall require it to consist of at least 1 capital letter, 1 number, and will consist of a length of a minimum of 8 digits.<br/><br/>
            The Company in compliance with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;<br/><br/>
            The responsibility for all transactions and other activity placed or conducted through the user account releases The Company from any and all liability concerning such transactions or activities;<br/><br/>
            Thereof will contact <a href='mailto:support@watersports-everywhere.com'>support@watersports-everywhere.com</a> immediately for any actual or suspected loss, theft, or unauthorized use of the account or password. The service will be used in a good manner.<br/><br/>
            <b>Watersports Everywhere Status</b><br/>
            The Watersports Everywhere app will show different Watersports Everywhere booking statuses, each one will represent a specific situation of the Watersports Everywhere booking process.<br/><br/>
            <ol style='list-style: disc'>
                <li>&#8594; In Progress<br/>The status in progress converges to the Watersports Everywhere booking that has been purchased, yet the process of creating the booking is not yet confirmed due to operational reasons from a supplier. Consequently, the Watersports Everywhere booking cannot be edited since all the necessary data has been sent previously.</li>
                <li>&#8594; Confirmed<br/>This is the final status of the Watersports Everywhere booking process, wherein the definitive version of the Watersports Everywhere booking is received and can be viewed by the user. Once the Watersports Everywhere booking is confirmed, there is an option for non-refundable or cancellations available, for more information please refer to the section Cancellations.</li>
            </ol>
        `,
  },
  {
    title: 'Fees and Payments for Purchased Services',
    content: `
            Payments may be made using one of the payment methods set out in the Watersports Everywhere app. The user payments can only be made using a valid credit card. We reserve the right to not process your booking (i) if your card is declined for any reason, (ii) if the payment card has been used fraudulently or (iii) without the cardholder's permission or (iv) without the cardholder's tutor’s permission or (v) if you are underage.<br/><br/>
            For the payment method, an authorization hold will be issued to the Credit Card with the total amount of costs surrounding the purchase of Watersports Everywhere booking. When the booking is still in the processing stage, no charges shall be applied to the Credit Card. The total amount of the charges shall be reserved and deducted from the account holder's Credit Card only after the Watersports Everywhere booking has been confirmed.<br/><br/>
            The hold-authorisation feature works in four steps:<br/><br/>
            <ol>
                <li>When the user requests a Watersports Everywhere booking by using the Watersports Everywhere app, an authorisation hold is placed on the Credit Card used, amounting to the upfront fare. Once the Watersports Everywhere booking is confirmed, the pending payment is converted to a final charge, and only then is the account debited.</li>
                <li>If the issued Credit Card account balance is lower than the necessary amount billed, the authorisation hold will fail. If this occurs, the user will have to select a different payment method or add a new payment method to their account in order to request a Watersports Everywhere booking.</li>
                <li>When the user has completed the booking procedure by providing all the necessary details, including the requirements, the status of the Watersports Everywhere booking will be presented. The user is responsible for supplying valid information as it is not possible to modify or cancel the purchase once the Watersports Everywhere booking is made. Additionally, The Company cannot be held responsible for non-delivery due to transmission failure or incorrect details provided by the user. The user agrees that the payment card information from the related user may be provided to third parties such as governmental and/or anti-fraud institutions, for payment processing and fraud prevention purposes.</li>
                <li>The applicable fees and taxes in the Watersports Everywhere App for the services applied may change at any time and shall come into effect immediately. The user agrees to pay all applicable, undisputed fees and taxes for the services on the terms laid out in the invoice.</li>
            </ol>
        `,
  },
  {
    title: 'Cancellations & Termination',
    content: `
            The user may opt to cancel any Watersports Everywhere booking in the process status. When the user decides to cancel a Watersports Everywhere booking which is already in progress, the authorisation hold of the Credit Card will be immediately released.<br/><br/>
            If the status of your Watersports Everywhere booking is “pending” or “in progress”, any cancellation can be made. Once the user has written the names and discovered a mistake, the processable Watersports Everywhere booking can be cancelled, releasing the authorization hold amount charged in the credit card and may place a new order. Please note, there are no cancellations possible after the Watersports Everywhere booking is confirmed. Moreover, since there is an authorization hold applicable, refunds are not valid. The Service shall also be terminated by the Watersports Everywhere App in case of a delinquent Account or in case of a breach of any of the user’s obligations as outlined in the Terms of service established here.<br/><br/>
            Customers will receive a full refund or credit in case of operator cancellation due to weather or other unforeseen circumstances.<br/><br/>
            <ol style='list-style: disc'>
                <li>Cancellations 6 calendar days up to 48 hours before departure will receive a 50% refund.</li>
                <li>Cancellations 24 hours before departure will receive a 20% refund.</li>
                <li>Cancellations made less than 24 hours before departure there is no refund.</li>
                <li>No-shows will be charged the full price.</li>
            </ol>
            Contact us to cancel or inquire further about a cancellation.
        `,
  },
  {
    title: 'Confidentiality and Privacy',
    content: `
            This section is used to inform users regarding the policies surrounding the collection, use, and disclosure of Personal Information if anyone decides to use the Service. If users choose to make use of our Service, then they agree to the collection and use of information in relation to this policy. The Personal Information that is collected, is used for providing and improving the Service. This Personal Information shall not be used or shared with others different than as described in this Privacy Policy. The terms used in the Privacy Policy have the same meanings as in our Terms of Service, which is accessible by the User, unless otherwise defined in this Privacy Policy.<br/><br/>
            <ol>
                <li>Information Collection and Use<br/>For a better experience, while using the Watersports Everywhere App, the user may be required to provide certain personally identifiable information. The information requested shall be retained, and used as described in the privacy policy.</li>
                <li>Security<br/>Users’ trustful providence of Personal Information is valued by The Company, thus, it is strived to use commercially acceptable means of protecting this data. Remember that no method of transmission over the Internet, or method of electronic storage is 100% secure and reliable, therefore, no absolute security can be guaranteed.</li>
                <li>Children’s Privacy<br/>These Services do not address persons underage. The Company does not knowingly collect personally identifiable information from underage. In the case where it is discovered that an underage person has provided us with personal information, this information shall be deleted immediately from the app’s servers. If the user is a parent or guardian/tutor, and is aware that their child has provided Personal Information, please contact The Company in order to undertake the necessary actions. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.</li>
                <li>Changes to the Privacy Policy<br/>The Company may update its Privacy Policy periodically. Thus, the user is advised to review this page periodically for any changes. The user shall be notified of any changes made by publishing the new Privacy Policy on this page. These changes are effective immediately after publication on this document.</li>
            </ol>
            Contact Us<br/><br/>
            For any further questions please read our Privacy Policy, please do not hesitate to contact The Company at <a href='mailto:terms@watersports-everywhere.com'>terms@watersports-everywhere.com</a>.
        `,
  },
  {
    title: 'Jurisdiction',
    content: `
            With express waiver to any other jurisdiction that may correspond to the Parties, any dispute or controversy in relation to, in connection with or resulting from this License shall be exclusively resolved by the courts of the city of London (England). This License will be governed by and construed under the laws of the Kingdom of England.
        `,
  },
  {
    title: 'Refund',
    content: `
            The way the refund is handled, depends on the original payment method.<br/><br/>
            Once the request for refund is received, it will be inspected and the user shall be notified if all the requested documentation is correct.<br/><br/>
            The user shall immediately be notified on the status of their refund after inspection of the documentation. After that, a refund shall be sent to the user’s Credit Card (or through the original payment method). Once payment to third parties has been effective, ABBM is tied to obligations. As a result, the total refund might be up to a certain percentage. All refunds are processed in the currency used by the user, and will reflect the exchange rate in effect on the date of the refund, therefore, all refunds are subject to this fluctuation. ADVANCED BOAT BOOKING AND MORE LTD. is not responsible for any change in exchange rates between the time of payment and time of refund.<br/><br/>
            The user will receive the refund within a certain amount of days, depending on the Credit Card issuer's policies.<br/><br/>
            ADVANCED BOAT BOOKING AND MORE LTD. reserves the right to provide a refund percentage based on its discretion.<br/><br/>
            Users are eligible for reimbursement if they request a refund within 15 calendar days starting from the date written on the Watersports Everywhere booking confirmation.<br/><br/>
            After the 15-day period users shall no longer be eligible and shall not be able to receive a refund.<br/><br/>
            <b>Late or Missing Refunds</b><br/>
            If it becomes apparent that a refund has not yet been received, users are advised to check their bank account once again. Then contact the Credit Card company; it may take some time before the refund is officially posted. Next contact the bank; there is often some processing time before a refund is posted. If users have taken all these steps and still have not received the refund, please contact the Company via email (<a href='mailto:refunds@watersports-everywhere.com'>refunds@watersports-everywhere.com</a>).<br/><br/>
            THE USER EXPRESSLY ACKNOWLEDGES TO HAVE READ THE TERMS OF USE AND UNDERSTANDS THE RIGHTS, AND OBLIGATIONS SET FORTH HEREIN. BY ACCESSING OR OTHERWISE USING OUR SERVICES (EG, VISITING THE WEBSITE, SEARCHING FOR AVAILABILITY, CONSULT PRICES, OR MAKE A PURCHASE, OR REGISTERING AN ACCOUNT) AND/OR CONTINUING TO INSTALL OR USE THE COMPANY’S SOFTWARE APPLICATION AND SERVICES, THE USER EXPRESSLY CONSENTS TO BE BOUND BY ITS TERMS OF SERVICE AND GRANTS TO ADVANCED BOAT BOOKING AND MORE LTD. THE RIGHTS SET FORTH HEREIN.
        `,
  },
]

export const MAX_PAGE_SIZE = 200
export const DEFAULT_PAGE_SIZE = 10

export const CURRENCIES: { code: string; name: string; country_code: string; symbol: string }[] = [
  { code: 'AED', name: 'United Arab Emirates Dirham', country_code: 'AE', symbol: 'د.إ' },
  { code: 'AFN', name: 'Afghan Afghani', country_code: 'AF', symbol: '؋' },
  { code: 'ALL', name: 'Albanian Lek', country_code: 'AL', symbol: 'L' },
  { code: 'AMD', name: 'Armenian Dram', country_code: 'AM', symbol: '֏' },
  { code: 'ANG', name: 'Netherlands Antillean Guilder', country_code: 'NL', symbol: 'ƒ' },
  { code: 'AOA', name: 'Angolan Kwanza', country_code: 'AO', symbol: 'Kz' },
  { code: 'ARS', name: 'Argentine Peso', country_code: 'AR', symbol: '$' },
  { code: 'AUD', name: 'Australian Dollar', country_code: 'AU', symbol: 'A$' },
  { code: 'AWG', name: 'Aruban Florin', country_code: 'AW', symbol: 'ƒ' },
  { code: 'AZN', name: 'Azerbaijani Manat', country_code: 'AZ', symbol: '₼' },
  { code: 'BAM', name: 'Bosnia-Herzegovina Convertible Mark', country_code: 'BA', symbol: 'KM' },
  { code: 'BBD', name: 'Barbadian Dollar', country_code: 'BB', symbol: 'Bds$' },
  { code: 'BDT', name: 'Bangladeshi Taka', country_code: 'BD', symbol: '৳' },
  { code: 'BGN', name: 'Bulgarian Lev', country_code: 'BG', symbol: 'лв' },
  { code: 'BHD', name: 'Bahraini Dinar', country_code: 'BH', symbol: '.د.ب' },
  { code: 'BIF', name: 'Burundian Franc', country_code: 'BI', symbol: 'FBu' },
  { code: 'BMD', name: 'Bermudian Dollar', country_code: 'BM', symbol: 'BD$' },
  { code: 'BND', name: 'Brunei Dollar', country_code: 'BN', symbol: 'B$' },
  { code: 'BOB', name: 'Bolivian Boliviano', country_code: 'BO', symbol: 'Bs' },
  { code: 'BRL', name: 'Brazilian Real', country_code: 'BR', symbol: 'R$' },
  { code: 'BSD', name: 'Bahamian Dollar', country_code: 'BS', symbol: 'B$' },
  { code: 'BTN', name: 'Bhutanese Ngultrum', country_code: 'BT', symbol: 'Nu.' },
  { code: 'BWP', name: 'Botswana Pula', country_code: 'BW', symbol: 'P' },
  { code: 'BYN', name: 'Belarusian Ruble', country_code: 'BY', symbol: 'Br' },
  { code: 'BZD', name: 'Belize Dollar', country_code: 'BZ', symbol: 'BZ$' },
  { code: 'CAD', name: 'Canadian Dollar', country_code: 'CA', symbol: 'C$' },
  { code: 'CDF', name: 'Congolese Franc', country_code: 'CD', symbol: 'FC' },
  { code: 'CHF', name: 'Swiss Franc', country_code: 'CH', symbol: 'CHF' },
  { code: 'CLP', name: 'Chilean Peso', country_code: 'CL', symbol: '$' },
  { code: 'CNY', name: 'Chinese Yuan', country_code: 'CN', symbol: '¥' },
  { code: 'COP', name: 'Colombian Peso', country_code: 'CO', symbol: '$' },
  { code: 'CRC', name: 'Costa Rican Colón', country_code: 'CR', symbol: '₡' },
  { code: 'CUP', name: 'Cuban Peso', country_code: 'CU', symbol: '$' },
  { code: 'CVE', name: 'Cape Verdean Escudo', country_code: 'CV', symbol: 'Esc' },
  { code: 'CZK', name: 'Czech Koruna', country_code: 'CZ', symbol: 'Kč' },
  { code: 'DJF', name: 'Djiboutian Franc', country_code: 'DJ', symbol: 'Fdj' },
  { code: 'DKK', name: 'Danish Krone', country_code: 'DK', symbol: 'kr' },
  { code: 'DOP', name: 'Dominican Peso', country_code: 'DO', symbol: 'RD$' },
  { code: 'DZD', name: 'Algerian Dinar', country_code: 'DZ', symbol: 'دج' },
  { code: 'EGP', name: 'Egyptian Pound', country_code: 'EG', symbol: '£' },
  { code: 'ERN', name: 'Eritrean Nakfa', country_code: 'ER', symbol: 'Nkf' },
  { code: 'ETB', name: 'Ethiopian Birr', country_code: 'ET', symbol: 'Br' },
  { code: 'EUR', name: 'Euro', country_code: 'EU', symbol: '€' },
  { code: 'FJD', name: 'Fijian Dollar', country_code: 'FJ', symbol: 'FJ$' },
  { code: 'FKP', name: 'Falkland Islands Pound', country_code: 'FK', symbol: '£' },
  { code: 'FOK', name: 'Faroese Króna', country_code: 'FO', symbol: 'kr' },
  { code: 'GBP', name: 'British Pound', country_code: 'GB', symbol: '£' },
  { code: 'GEL', name: 'Georgian Lari', country_code: 'GE', symbol: '₾' },
  { code: 'GGP', name: 'Guernsey Pound', country_code: 'GG', symbol: '£' },
  { code: 'GHS', name: 'Ghanaian Cedi', country_code: 'GH', symbol: '₵' },
  { code: 'GIP', name: 'Gibraltar Pound', country_code: 'GI', symbol: '£' },
  { code: 'GMD', name: 'Gambian Dalasi', country_code: 'GM', symbol: 'D' },
  { code: 'GNF', name: 'Guinean Franc', country_code: 'GN', symbol: 'FG' },
  { code: 'GTQ', name: 'Guatemalan Quetzal', country_code: 'GT', symbol: 'Q' },
  { code: 'GYD', name: 'Guyanese Dollar', country_code: 'GY', symbol: 'G$' },
  { code: 'HKD', name: 'Hong Kong Dollar', country_code: 'HK', symbol: 'HK$' },
  { code: 'HNL', name: 'Honduran Lempira', country_code: 'HN', symbol: 'L' },
  { code: 'HRK', name: 'Croatian Kuna', country_code: 'HR', symbol: 'kn' },
  { code: 'HTG', name: 'Haitian Gourde', country_code: 'HT', symbol: 'G' },
  { code: 'HUF', name: 'Hungarian Forint', country_code: 'HU', symbol: 'Ft' },
  { code: 'IDR', name: 'Indonesian Rupiah', country_code: 'ID', symbol: 'Rp' },
  { code: 'ILS', name: 'Israeli New Shekel', country_code: 'IL', symbol: '₪' },
  { code: 'IMP', name: 'Isle of Man Pound', country_code: 'IM', symbol: '£' },
  { code: 'INR', name: 'Indian Rupee', country_code: 'IN', symbol: '₹' },
  { code: 'IQD', name: 'Iraqi Dinar', country_code: 'IQ', symbol: 'ع.د' },
  { code: 'IRR', name: 'Iranian Rial', country_code: 'IR', symbol: '﷼' },
  { code: 'ISK', name: 'Icelandic Króna', country_code: 'IS', symbol: 'kr' },
  { code: 'JEP', name: 'Jersey Pound', country_code: 'JE', symbol: '£' },
  { code: 'JMD', name: 'Jamaican Dollar', country_code: 'JM', symbol: 'J$' },
  { code: 'JOD', name: 'Jordanian Dinar', country_code: 'JO', symbol: 'د.ا' },
  { code: 'JPY', name: 'Japanese Yen', country_code: 'JP', symbol: '¥' },
  { code: 'KES', name: 'Kenyan Shilling', country_code: 'KE', symbol: 'KSh' },
  { code: 'KGS', name: 'Kyrgyzstani Som', country_code: 'KG', symbol: 'с' },
  { code: 'KHR', name: 'Cambodian Riel', country_code: 'KH', symbol: '៛' },
  { code: 'KID', name: 'Kiribati Dollar', country_code: 'KI', symbol: '$' },
  { code: 'KMF', name: 'Comorian Franc', country_code: 'KM', symbol: 'CF' },
  { code: 'KRW', name: 'South Korean Won', country_code: 'KR', symbol: '₩' },
  { code: 'KWD', name: 'Kuwaiti Dinar', country_code: 'KW', symbol: 'د.ك' },
  { code: 'KYD', name: 'Cayman Islands Dollar', country_code: 'KY', symbol: 'CI$' },
  { code: 'KZT', name: 'Kazakhstani Tenge', country_code: 'KZ', symbol: '₸' },
  { code: 'LAK', name: 'Lao Kip', country_code: 'LA', symbol: '₭' },
  { code: 'LBP', name: 'Lebanese Pound', country_code: 'LB', symbol: 'ل.ل' },
  { code: 'LKR', name: 'Sri Lankan Rupee', country_code: 'LK', symbol: 'Rs' },
  { code: 'LRD', name: 'Liberian Dollar', country_code: 'LR', symbol: 'L$' },
  { code: 'LSL', name: 'Lesotho Loti', country_code: 'LS', symbol: 'M' },
  { code: 'LYD', name: 'Libyan Dinar', country_code: 'LY', symbol: 'ل.د' },
  { code: 'MAD', name: 'Moroccan Dirham', country_code: 'MA', symbol: 'د.م.' },
  { code: 'MDL', name: 'Moldovan Leu', country_code: 'MD', symbol: 'L' },
  { code: 'MGA', name: 'Malagasy Ariary', country_code: 'MG', symbol: 'Ar' },
  { code: 'MKD', name: 'Macedonian Denar', country_code: 'MK', symbol: 'ден' },
  { code: 'MMK', name: 'Burmese Kyat', country_code: 'MM', symbol: 'K' },
  { code: 'MNT', name: 'Mongolian Tögrög', country_code: 'MN', symbol: '₮' },
  { code: 'MOP', name: 'Macanese Pataca', country_code: 'MO', symbol: 'P' },
  { code: 'MRU', name: 'Mauritanian Ouguiya', country_code: 'MR', symbol: 'UM' },
  { code: 'MUR', name: 'Mauritian Rupee', country_code: 'MU', symbol: '₨' },
  { code: 'MVR', name: 'Maldivian Rufiyaa', country_code: 'MV', symbol: 'Rf' },
  { code: 'MWK', name: 'Malawian Kwacha', country_code: 'MW', symbol: 'MK' },
  { code: 'MXN', name: 'Mexican Peso', country_code: 'MX', symbol: '$' },
  { code: 'MYR', name: 'Malaysian Ringgit', country_code: 'MY', symbol: 'RM' },
  { code: 'MZN', name: 'Mozambican Metical', country_code: 'MZ', symbol: 'MT' },
  { code: 'NAD', name: 'Namibian Dollar', country_code: 'NA', symbol: 'N$' },
  { code: 'NGN', name: 'Nigerian Naira', country_code: 'NG', symbol: '₦' },
  { code: 'NIO', name: 'Nicaraguan Córdoba', country_code: 'NI', symbol: 'C$' },
  { code: 'NOK', name: 'Norwegian Krone', country_code: 'NO', symbol: 'kr' },
  { code: 'NPR', name: 'Nepalese Rupee', country_code: 'NP', symbol: '₨' },
  { code: 'NZD', name: 'New Zealand Dollar', country_code: 'NZ', symbol: 'NZ$' },
  { code: 'OMR', name: 'Omani Rial', country_code: 'OM', symbol: 'ر.ع.' },
  { code: 'PAB', name: 'Panamanian Balboa', country_code: 'PA', symbol: 'B/.' },
  { code: 'PEN', name: 'Peruvian Sol', country_code: 'PE', symbol: 'S/.' },
  { code: 'PGK', name: 'Papua New Guinean Kina', country_code: 'PG', symbol: 'K' },
  { code: 'PHP', name: 'Philippine Peso', country_code: 'PH', symbol: '₱' },
  { code: 'PKR', name: 'Pakistani Rupee', country_code: 'PK', symbol: '₨' },
  { code: 'PLN', name: 'Polish Złoty', country_code: 'PL', symbol: 'zł' },
  { code: 'PYG', name: 'Paraguayan Guaraní', country_code: 'PY', symbol: '₲' },
  { code: 'QAR', name: 'Qatari Riyal', country_code: 'QA', symbol: 'ر.ق' },
  { code: 'RON', name: 'Romanian Leu', country_code: 'RO', symbol: 'lei' },
  { code: 'RSD', name: 'Serbian Dinar', country_code: 'RS', symbol: 'дин' },
  { code: 'RUB', name: 'Russian Ruble', country_code: 'RU', symbol: '₽' },
  { code: 'RWF', name: 'Rwandan Franc', country_code: 'RW', symbol: 'FRw' },
  { code: 'SAR', name: 'Saudi Riyal', country_code: 'SA', symbol: 'ر.س' },
  { code: 'SBD', name: 'Solomon Islands Dollar', country_code: 'SB', symbol: 'SI$' },
  { code: 'SCR', name: 'Seychellois Rupee', country_code: 'SC', symbol: '₨' },
  { code: 'SDG', name: 'Sudanese Pound', country_code: 'SD', symbol: 'ج.س.' },
  { code: 'SEK', name: 'Swedish Krona', country_code: 'SE', symbol: 'kr' },
  { code: 'SGD', name: 'Singapore Dollar', country_code: 'SG', symbol: 'S$' },
  { code: 'SHP', name: 'Saint Helena Pound', country_code: 'SH', symbol: '£' },
  { code: 'SLL', name: 'Sierra Leonean Leone', country_code: 'SL', symbol: 'Le' },
  { code: 'SOS', name: 'Somali Shilling', country_code: 'SO', symbol: 'Sh' },
  { code: 'SRD', name: 'Surinamese Dollar', country_code: 'SR', symbol: '$' },
  { code: 'SSP', name: 'South Sudanese Pound', country_code: 'SS', symbol: '£' },
  { code: 'STN', name: 'São Tomé and Príncipe Dobra', country_code: 'ST', symbol: 'Db' },
  { code: 'SYP', name: 'Syrian Pound', country_code: 'SY', symbol: '£' },
  { code: 'SZL', name: 'Eswatini Lilangeni', country_code: 'SZ', symbol: 'E' },
  { code: 'THB', name: 'Thai Baht', country_code: 'TH', symbol: '฿' },
  { code: 'TJS', name: 'Tajikistani Somoni', country_code: 'TJ', symbol: 'SM' },
  { code: 'TMT', name: 'Turkmenistani Manat', country_code: 'TM', symbol: 'T' },
  { code: 'TND', name: 'Tunisian Dinar', country_code: 'TN', symbol: 'د.ت' },
  { code: 'TOP', name: 'Tongan Paʻanga', country_code: 'TO', symbol: 'T$' },
  { code: 'TRY', name: 'Turkish Lira', country_code: 'TR', symbol: '₺' },
  { code: 'TTD', name: 'Trinidad and Tobago Dollar', country_code: 'TT', symbol: 'TT$' },
  { code: 'TVD', name: 'Tuvaluan Dollar', country_code: 'TV', symbol: '$' },
  { code: 'TWD', name: 'New Taiwan Dollar', country_code: 'TW', symbol: 'NT$' },
  { code: 'TZS', name: 'Tanzanian Shilling', country_code: 'TZ', symbol: 'Sh' },
  { code: 'UAH', name: 'Ukrainian Hryvnia', country_code: 'UA', symbol: '₴' },
  { code: 'UGX', name: 'Ugandan Shilling', country_code: 'UG', symbol: 'Sh' },
  { code: 'USD', name: 'United States Dollar', country_code: 'US', symbol: '$' },
  { code: 'UYU', name: 'Uruguayan Peso', country_code: 'UY', symbol: '$U' },
  { code: 'UZS', name: 'Uzbekistani Soʻm', country_code: 'UZ', symbol: 'сўм' },
  { code: 'VED', name: 'Venezuelan Bolívar', country_code: 'VE', symbol: 'Bs.S.' },
  { code: 'VND', name: 'Vietnamese Đồng', country_code: 'VN', symbol: '₫' },
  { code: 'VUV', name: 'Vanuatu Vatu', country_code: 'VU', symbol: 'VT' },
  { code: 'WST', name: 'Samoan Tālā', country_code: 'WS', symbol: 'WS$' },
  { code: 'XAF', name: 'Central African CFA Franc', country_code: 'CF', symbol: 'FCFA' },
  { code: 'XCD', name: 'Eastern Caribbean Dollar', country_code: 'AG', symbol: '$' },
  { code: 'XOF', name: 'West African CFA Franc', country_code: 'BF', symbol: 'CFA' },
  { code: 'XPF', name: 'CFP Franc', country_code: 'PF', symbol: '₣' },
  { code: 'YER', name: 'Yemeni Rial', country_code: 'YE', symbol: '﷼' },
  { code: 'ZAR', name: 'South African Rand', country_code: 'ZA', symbol: 'R' },
  { code: 'ZMW', name: 'Zambian Kwacha', country_code: 'ZM', symbol: 'ZK' },
  { code: 'ZWL', name: 'Zimbabwean Dollar', country_code: 'ZW', symbol: 'Z$' },
]

export const VENDORS = [
  'Beach Water Sport',
  'Octopus Jet Ski Rental',
  'Watersports by First Yacht',
  'Jet Ski & More | Watersports South Beach',
  'Water Adventure Dubai Palm West Beach',
  'Fun Beach Water Sports',
  'Nemo WaterSports Jet Ski Dubai & Flyboard',
  'Aone WaterSports Jetski Dubai',
  'Shark Jetski Rental Jumierah',
  'Yoush Water Sports Jet Ski Dubai',
  'Popeye Jetski Rental',
  'Xtreme Watersports (Dubai)',
  'BlueSea Dubai Jetski',
  'Yoush Water Sports Jet Ski Dubai',
  'Sun And Fun',
  'Seabreacher Diving Club and Water Sports - Jumeirah',
  'MYJETSKI',
  'Jet Ski Dubai Adventures',
  'Beach Riders Dubai- JBR',
  'Sky & Sea Adventures',
]

export const MIN_DURATION_OPTIONS = 1
export const MIN_ITEMS_FOR_MARGIN = 2
export const MAX_ITEMS_FOR_MARGIN = 5
export const MAX_LIMIT_FOR_ZOOM_OUT = 9
export const MAX_LIMIT_PAGE_SIZE_NEAR_BY_LOCATION = 20
export const INITIAL_MAP_ZOOM_LEVEL = 12
