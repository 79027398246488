import { BottomSheet } from '@watersport/ui-react-next'
import React, { useCallback } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { useNavigate } from 'react-router-dom'

import { useSearch } from 'contexts/SearchProvider'

export interface MenuProps {
  show: boolean
  onClose: () => void
  onShowCurrency: () => void
}

const menus = [
  { label: 'Privacy Policy', link: 'privacy-policy' },
  { label: 'Terms', link: 'terms-conditions' },
]

const Menu: React.FC<MenuProps> = ({ show, onClose, onShowCurrency }) => {
  const { currency } = useSearch()
  const navigate = useNavigate()

  const onClickItem = useCallback(
    (link: string) => {
      onClose()
      navigate(`/${link}`)
    },
    [navigate, onClose],
  )

  const onClickCurrency = useCallback(() => {
    onClose()
    onShowCurrency()
  }, [onClose, onShowCurrency])

  return (
    <BottomSheet
      show={show}
      title={'Menu'}
      onClose={onClose}
      fullHeight={true}
      headerColor={'white'}
      side={'left'}
    >
      <div className="pl-10">
        {menus.map((item, index) => (
          <p
            className="flex items-center text-base py-4 border-b border-blue-80"
            key={index}
            onClick={() => onClickItem(item.link)}
          >
            {item.label}
          </p>
        ))}
        <div
          className="flex items-center gap-4 border rounded-full p-3 mr-10 mt-12"
          onClick={onClickCurrency}
        >
          <CircleFlag
            className="h-8"
            countryCode={currency?.country_code?.toLocaleLowerCase() || 'us'}
          />
          <p className="flex items-center flex-1 font-normal text-base">{currency?.name}</p>
          <p className="pr-4 font-semibold text-base">{currency?.symbol}</p>
        </div>
      </div>
    </BottomSheet>
  )
}

export default Menu
