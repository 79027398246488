export const validatePolygonString = (
  polygonString: string | undefined,
): [number, number][][] | undefined => {
  if (!polygonString) return undefined
  try {
    const parsedPolygons = JSON.parse(polygonString)

    if (
      Array.isArray(parsedPolygons) &&
      parsedPolygons.every(
        polygon =>
          Array.isArray(polygon) &&
          polygon.every(
            coordinates =>
              Array.isArray(coordinates) &&
              coordinates.length === 2 &&
              coordinates.every(c => typeof c === 'number'),
          ),
      )
    ) {
      return parsedPolygons
    }
    return undefined
  } catch (e) {
    return undefined
  }
}
