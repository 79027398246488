import { useCallback, useState } from 'react'

import { useModal } from 'features/common/hooks/useModal'

export interface IUseMyLocation<T> {
  myLocation: google.maps.LatLngLiteral | undefined
  requestLocation: () => void
  checkLocationPermission: () => void
  showPermission: boolean
  confirm: (s: T) => void
  showModal: () => Promise<T>
  closeModal: () => void
  permissionStatus: {
    isPermissionDenied: boolean
    permissionDeniedReason: string | null
  }
}

export const useMyLocation = (): IUseMyLocation<boolean> => {
  const [myLocation, setMyLocation] = useState<google.maps.LatLngLiteral | undefined>(undefined)
  const { show: showPermission, confirm, showModal, closeModal } = useModal<boolean>()
  const [permissionStatus, setPermissionStatus] = useState<{
    isPermissionDenied: boolean
    permissionDeniedReason: string | null
  }>({
    isPermissionDenied: false,
    permissionDeniedReason: null,
  })

  const requestLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          setMyLocation({ lat: latitude, lng: longitude })
          setPermissionStatus({ isPermissionDenied: false, permissionDeniedReason: null })
        },
        error => {
          if (error.code === error.PERMISSION_DENIED) {
            setPermissionStatus({
              isPermissionDenied: true,
              permissionDeniedReason: 'User denied the request for Geolocation.',
            })
          }
          setMyLocation(undefined)
        },
      )
    } else {
      setPermissionStatus({
        isPermissionDenied: false,
        permissionDeniedReason: 'Geolocation is not supported by this browser.',
      })
    }
  }, [])

  const checkLocationPermission = useCallback(() => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(permissionResult => {
        if (permissionResult.state === 'denied') {
          setPermissionStatus({
            isPermissionDenied: true,
            permissionDeniedReason: 'User denied the request for Geolocation.',
          })
        } else if (permissionResult.state === 'granted') {
          requestLocation()
        }
      })
    }
  }, [requestLocation])

  return {
    showPermission,
    confirm,
    showModal,
    closeModal,
    myLocation,
    requestLocation,
    checkLocationPermission,
    permissionStatus,
  }
}
