import { useMemo } from 'react'

import { useInitialParams } from 'features/common/hooks/useInitialParams'
import {
  FilterCategory,
  InventoryGroupSortType,
  MapSearchParams,
  MetaSearchResponse,
} from 'features/common/types'
import { useGetFilterCategoryQuery } from 'store/booking/api'

export interface IUseInitialFilterOption {
  isLoading: boolean
  filterOption: FilterCategory | undefined
  sortBy?: InventoryGroupSortType
  mapBounds?: MapSearchParams
  filteredCount: number
}

export const useInitialFilterOption = (
  metaSearchData: MetaSearchResponse | undefined,
  vendorId: string | undefined,
  currency: string,
): IUseInitialFilterOption => {
  const { params, filter: filterParams, sortBy, mapBounds } = useInitialParams()

  const activityIds = useMemo(
    () => metaSearchData?.activities?.map(a => a._id),
    [metaSearchData?.activities],
  )

  const data = useMemo(() => {
    if (vendorId) {
      return {
        currency,
        vendorId,
      }
    }
    return {
      locationId: metaSearchData?.location?._id,
      pin_lat: params.lat,
      pin_lng: params.lng,
      radius: params.radius,
      polygons: params.polygons && JSON.stringify(params.polygons),
      activityIds,
      currency,
      vendorId,
    }
  }, [
    activityIds,
    currency,
    metaSearchData?.location?._id,
    params.lat,
    params.lng,
    params.radius,
    params.polygons,
    vendorId,
  ])

  const { data: res, isLoading } = useGetFilterCategoryQuery(data, {
    skip:
      (!metaSearchData?.location?._id &&
        !(params.lat && params.lng) &&
        !vendorId &&
        !params.polygons) ||
      (activityIds?.length === 0 && !vendorId),
    refetchOnMountOrArgChange: true,
  })

  const filterOption = useMemo(() => {
    if (!res) return res
    let minPrice = filterParams.minPrice || 0
    let maxPrice = filterParams.maxPrice || 0
    const baseMin = res.min_price?.value || 0
    const baseMax = res.max_price?.value || 0
    if (!(minPrice >= baseMin && minPrice <= baseMax && minPrice <= maxPrice)) {
      minPrice = baseMin
    }
    if (!(maxPrice >= baseMin && maxPrice <= baseMax && minPrice <= maxPrice)) {
      maxPrice = baseMax
    }
    return {
      ...res,
      types: res.types.map(t => ({ ...t, selected: filterParams.types?.includes(t._id) ?? false })),
      durations: res.durations.map(d => ({
        ...d,
        selected: filterParams.durations?.includes(d.value.value_in_mins?.toString()) ?? false,
      })),
      min_review_rating: filterParams.minReviewRating ?? res.min_review_rating,
      min_price: res.min_price ? { ...res.min_price, selected: minPrice } : undefined,
      max_price: res.max_price ? { ...res.max_price, selected: maxPrice } : undefined,
    }
  }, [
    filterParams.durations,
    filterParams.maxPrice,
    filterParams.minPrice,
    filterParams.minReviewRating,
    filterParams.types,
    res,
  ])

  const filteredCount = useMemo(() => {
    let count = 0
    if ((filterOption?.types.filter(t => t.selected) ?? []).length > 0) {
      count += 1
    }
    if ((filterOption?.durations.filter(d => d.selected) ?? []).length > 0) {
      count += 1
    }
    if (
      (filterOption?.max_price?.selected ?? 0) !== 0 &&
      (filterOption?.max_price?.value ?? 0) !== (filterOption?.max_price?.selected ?? 0)
    ) {
      count += 1
    }
    if (
      (filterOption?.min_price?.selected ?? 0) !== 0 &&
      (filterOption?.min_price?.value ?? 0) !== (filterOption?.min_price?.selected ?? 0)
    ) {
      count += 1
    }
    if (filterOption?.min_review_rating !== undefined) {
      count += 1
    }
    return count
  }, [
    filterOption?.durations,
    filterOption?.max_price?.selected,
    filterOption?.max_price?.value,
    filterOption?.min_price?.selected,
    filterOption?.min_price?.value,
    filterOption?.min_review_rating,
    filterOption?.types,
  ])

  return {
    isLoading,
    filterOption,
    sortBy,
    mapBounds:
      mapBounds.neLng && mapBounds.neLat && mapBounds.swLat && mapBounds.swLng
        ? mapBounds
        : undefined,
    filteredCount,
  }
}
