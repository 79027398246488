import { createSlice } from '@reduxjs/toolkit'

import {
  Location,
  LocationResponse,
  InventoryGroupResponse,
  InventoryGroup,
} from 'features/common/types'
import { searchApi } from 'store/booking/api'
import { RootState } from 'store/store'

export interface BookingState {
  locations: Location[]
  inventoryGroups: InventoryGroup[]
}

const initialState: BookingState = {
  locations: [],
  inventoryGroups: [],
}

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      searchApi.endpoints.getLocations.matchFulfilled,
      (state, { payload }: { payload: LocationResponse }) => {
        if (payload.page === 1) {
          state.locations = payload.items
        } else {
          state.locations = [...state.locations, ...payload.items]
        }
      },
    )
    builder.addMatcher(
      searchApi.endpoints.getInventoryGroups.matchFulfilled,
      (state, { payload }: { payload: InventoryGroupResponse }) => {
        if (payload.page === 1) {
          state.inventoryGroups = payload.items
        } else {
          state.inventoryGroups = [...state.inventoryGroups, ...payload.items]
        }
      },
    )
  },
})

export const selectBooking = (state: RootState) => state.booking

export default bookingSlice
