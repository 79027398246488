import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { validatePolygonString } from 'features/common/helpers/validatePolygonString'
import {
  InitialFilterParams,
  InitialParams,
  InventoryGroupSortType,
  MapSearchParams,
} from 'features/common/types'

export interface IUseInitialParams {
  params: InitialParams
  filter: InitialFilterParams
  mapBounds: MapSearchParams
  sortBy?: InventoryGroupSortType
  skipCall: boolean
  updateSearchParam: (params: { key: string; value: string | number | undefined }[]) => void
  setNewSearchParam: (params: { key: string; value: string | number | undefined }[]) => void
}

export const useInitialParams = (): IUseInitialParams => {
  const [searchParams, setSearchParams] = useSearchParams()

  const activityIds = useMemo(
    () => searchParams.get('activityIds')?.split(',') || [],
    [searchParams],
  )
  const locationId = useMemo(() => searchParams.get('locationId') || undefined, [searchParams])
  const location = useMemo(() => searchParams.get('location') || undefined, [searchParams])
  const activities = useMemo(() => searchParams.get('activities')?.split(',') || [], [searchParams])
  const lat = useMemo(() => searchParams.get('pin_lat') || undefined, [searchParams])
  const lng = useMemo(() => searchParams.get('pin_lng') || undefined, [searchParams])
  const radius = useMemo(() => searchParams.get('radius') || undefined, [searchParams])
  const polygons = useMemo(
    () => validatePolygonString(searchParams.get('polygons') || undefined),
    [searchParams],
  )
  const tags = useMemo(() => searchParams.get('tags')?.split(',') || [], [searchParams])
  const fromView = useMemo(() => searchParams.get('from_view') || undefined, [searchParams])

  const types = useMemo(() => searchParams.get('types')?.split(','), [searchParams])
  const durations = useMemo(() => searchParams.get('durations')?.split(','), [searchParams])
  const minPrice = useMemo(() => searchParams.get('min_price') || undefined, [searchParams])
  const maxPrice = useMemo(() => searchParams.get('max_price') || undefined, [searchParams])
  const currency = useMemo(() => searchParams.get('currency') || undefined, [searchParams])
  const minReviewRating = useMemo(
    () => searchParams.get('min_review_rating') || undefined,
    [searchParams],
  )
  const neLat = useMemo(() => searchParams.get('ne_lat') || undefined, [searchParams])
  const neLng = useMemo(() => searchParams.get('ne_lng') || undefined, [searchParams])
  const swLat = useMemo(() => searchParams.get('sw_lat') || undefined, [searchParams])
  const swLng = useMemo(() => searchParams.get('sw_lng') || undefined, [searchParams])
  const sortBy = useMemo(() => searchParams.get('sort_by') || undefined, [searchParams])

  const skipCall = useMemo(
    () => !locationId && !location && !(lat && lng) && !polygons && tags.length === 0,
    [lat, lng, location, locationId, polygons, tags],
  )

  const updateSearchParam = useCallback(
    (params: { key: string; value: string | number | undefined }[]) => {
      const newSearchParams = new URLSearchParams()
      params.forEach(({ key, value }) => {
        if (value) {
          newSearchParams.set(key, value.toString())
        } else {
          newSearchParams.delete(key)
        }
      })
      setSearchParams(newSearchParams)
    },
    [setSearchParams],
  )

  const setNewSearchParam = useCallback(
    (params: { key: string; value: string | number | undefined }[]) => {
      const newSearchParams = new URLSearchParams(window.location.search)
      params.forEach(({ key, value }) => {
        if (value) {
          newSearchParams.set(key, value.toString())
        } else {
          newSearchParams.delete(key)
        }
      })
      setSearchParams(newSearchParams)
    },
    [setSearchParams],
  )

  return {
    params: {
      locationId,
      locationName: location,
      activityIds,
      activities,
      lat: lat ? Number(lat) : undefined,
      lng: lng ? Number(lng) : undefined,
      radius: radius ? parseInt(radius, 10) : undefined,
      polygons,
      tags,
      fromView,
      currency,
    },
    filter: {
      types,
      durations,
      minPrice: minPrice ? Number(minPrice) : undefined,
      maxPrice: maxPrice ? Number(maxPrice) : undefined,
      minReviewRating: minReviewRating ? Number(minReviewRating) : undefined,
    },
    mapBounds: {
      neLat: neLat ? Number(neLat) : undefined,
      neLng: neLng ? Number(neLng) : undefined,
      swLat: swLat ? Number(swLat) : undefined,
      swLng: swLng ? Number(swLng) : undefined,
    },
    sortBy: sortBy === 'best_rating' ? 'best_rating' : 'lowest_price',
    skipCall,
    updateSearchParam,
    setNewSearchParam,
  }
}
