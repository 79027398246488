import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { createBrowserHistory } from 'history'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import logger from 'redux-logger'

import CONFIG from 'config'
import { searchApi } from 'store/booking/api'
import bookingSlice from 'store/booking/slice'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: process.env.REACT_APP_ENV === 'development',
  savePreviousLocations: 1,
})

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    [bookingSlice.name]: bookingSlice.reducer,
    [searchApi.reducerPath]: searchApi.reducer,
  }),
  devTools: CONFIG.debug,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(searchApi.middleware).concat(routerMiddleware).concat(logger),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const history = createReduxHistory(store)

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
