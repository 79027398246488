import React from 'react'
import { Outlet } from 'react-router-dom'

import { useSearch } from 'contexts/SearchProvider'
import Header from 'features/common/components/Header'
import LocationPermissionModal from 'features/common/components/LocationPermissionModal'
import { cn } from 'features/common/helpers/cn'

const Layout: React.FC = () => {
  const { showPermission, confirm, closeModal } = useSearch()

  return (
    <main className={cn('flex flex-col h-screen w-full bg-blue-900 max-w-md mx-auto md:max-w-md')}>
      <Header />
      <section className={cn('flex-1 h-full overflow-hidden')}>
        <Outlet />
      </section>
      <LocationPermissionModal show={showPermission} onClose={closeModal} onConfirm={confirm} />
    </main>
  )
}

export default Layout
