import { useEffect, useState } from 'react'

import { CURRENCIES } from 'constant'
import { getMyCurrency } from 'features/common/helpers/getMyCurrency'
import { useInitialParams } from 'features/common/hooks/useInitialParams'
import { Currency } from 'features/common/types'
import { useGetCurrenciesQuery } from 'store/booking/api'

export interface IUseCurrency {
  isLoading: boolean
  currency: Currency | undefined
  currencies: Currency[]
  setCurrency: (currency: Currency) => void
}

export const useCurrency = (): IUseCurrency => {
  const [currency, setCurrency] = useState<Currency | undefined>(undefined)

  const {
    params: { currency: currencyCode },
  } = useInitialParams()
  const { data: currencies, isLoading } = useGetCurrenciesQuery()

  useEffect(() => {
    const curr = currencyCode
      ? CURRENCIES.find(c => c.code === currencyCode.toUpperCase())
      : undefined

    if (curr) {
      setCurrency(curr)
    } else {
      getMyCurrency().then(setCurrency)
    }
  }, [currencyCode])

  return {
    isLoading,
    currency,
    currencies: currencies || [],
    setCurrency,
  }
}
