import axios from 'axios'

import { CURRENCIES } from 'constant'
import { Currency } from 'features/common/types'

export const getMyCurrency = async (): Promise<Currency | undefined> => {
  try {
    const { data } = await axios.get('https://ipapi.co/json/')
    const { currency } = data
    return CURRENCIES.find(c => c.code === (currency || 'USD'))
  } catch (e) {
    return CURRENCIES.find(c => c.code === 'USD')
  }
}
