import { Button, Modal } from '@watersport/ui-react-next'
import React from 'react'

import { useLayout } from 'contexts/LayoutProvider'
import { useSearch } from 'contexts/SearchProvider'

export interface LocationPermissionModalProps {
  show: boolean
  onClose: () => void
  onConfirm: (status: boolean) => void
}

const LocationPermissionModal: React.FC<LocationPermissionModalProps> = ({
  show,
  onClose,
  onConfirm,
}) => {
  const { activeRefs } = useLayout()
  const { permissionStatus } = useSearch()
  const { isPermissionDenied, permissionDeniedReason } = permissionStatus

  return (
    <Modal show={show} title={'Share location?'} onClose={onClose} activeRefs={activeRefs}>
      <div className="p-5">
        {isPermissionDenied ? (
          <span className="text-base tracking-tight">{permissionDeniedReason}</span>
        ) : (
          <div className="flex items-center justify-start gap-5">
            <Button
              variant={'outline'}
              text={"Don't share"}
              rounded
              size={'xl'}
              onClick={onClose}
            />
            <Button
              variant={'default'}
              text={'Yes, share'}
              rounded
              size={'xl'}
              onClick={() => onConfirm(true)}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default LocationPermissionModal
