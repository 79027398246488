import { useEffect } from 'react'

/*
In iPhone safari browser, vh is not working properly. this custom hook should be applied to app component
*/

export const useVh = () => {
  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }
    setVh()
    window.addEventListener('resize', setVh)

    return () => window.removeEventListener('resize', setVh)
  }, [])
}
