import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import App from 'App'
import CONFIG from 'config'
import setupHotjar from 'features/common/helpers/hotjar'
import fbq from 'features/common/helpers/metaPixel'
import reportWebVitals from 'reportWebVitals'
import { store } from 'store/store'

if (CONFIG.gtmId) {
  const tagManagerArgs = {
    gtmId: CONFIG.gtmId,
  }

  TagManager.initialize(tagManagerArgs)
}

if (CONFIG.metaPixelId) {
  fbq('set', 'autoConfig', CONFIG.metaPixelId, true)
  fbq('init', CONFIG.metaPixelId)
  fbq('track', 'PageView')
}

if (!CONFIG.debug) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY || '',
    plugins: [new BugsnagPluginReact()],
    appVersion: process.env.REACT_APP_VERSION || '0.1.0',
    releaseStage: process.env.REACT_APP_ENV,
  })
}

setupHotjar()

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || React.Fragment

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.StrictMode>
        <App />
        <ToastContainer />
      </React.StrictMode>
    </Provider>
  </ErrorBoundary>,
)

reportWebVitals()
