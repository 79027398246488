import { Menu as MenuIcon, Back } from '@watersport/ui-react-icons'
import { CurrencyModal, Logo } from '@watersport/ui-react-next'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CURRENCIES, SEARCH_PAGE } from 'constant'
import { useLayout } from 'contexts/LayoutProvider'
import { useSearch } from 'contexts/SearchProvider'
import Menu from 'features/common/components/Menu'
import { cn } from 'features/common/helpers/cn'

const Header: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showCurrency, setShowCurrency] = useState<boolean>(false)

  const { currency, setCurrency, setVendorId } = useSearch()
  const { showBack, showHeader } = useLayout()
  const navigate = useNavigate()

  const handleBackNavigation = useCallback(() => {
    setVendorId(undefined)
    navigate(-1)
  }, [setVendorId, navigate])

  return (
    <header
      className={cn(
        'relative flex items-center justify-between py-2.5 px-5 text-white overflow-hidden transition-all duration-100',
        showHeader ? 'py-2.5' : 'h-0 py-0',
      )}
    >
      <div className={'flex items-center'}>
        {showBack && <Back width={16} height={16} onClick={handleBackNavigation} />}
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2">
        <Logo url={SEARCH_PAGE} />
      </div>
      <div className="flex items-center justify-center py-2 px-1.5">
        <MenuIcon width={24} height={24} onClick={() => setShowMenu(true)} />
      </div>
      <Menu
        show={showMenu}
        onClose={() => setShowMenu(false)}
        onShowCurrency={() => setShowCurrency(true)}
      />
      <CurrencyModal
        show={showCurrency}
        currencies={CURRENCIES.map(c => c.code)}
        selectedCurrency={currency?.code}
        onSelectCurrency={setCurrency}
        onClose={() => setShowCurrency(false)}
      />
    </header>
  )
}

export default Header
