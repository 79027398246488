import React, { createContext, RefObject, useContext, useRef, useState } from 'react'

export interface AppProviderProps {
  children: React.ReactNode
}

export interface IContextProps {
  showHeader: boolean
  setShowHeader: (show: boolean) => void
  showBack: boolean
  setShowBack: (v: boolean) => void

  activeRefs: RefObject<HTMLDivElement>[]

  mapDrawingMode: 'select' | 'drawing' | undefined
  setMapDrawingMode: (drawingMode: 'select' | 'drawing' | undefined) => void
  showInstruction: boolean
  setShowInstruction: (v: boolean) => void
}

export const LayoutContext = createContext<IContextProps>({
  showHeader: true,
  setShowHeader: () => undefined,
  showBack: false,
  setShowBack: () => undefined,

  activeRefs: [],

  mapDrawingMode: undefined,
  setMapDrawingMode: () => undefined,

  showInstruction: false,
  setShowInstruction: () => undefined,
})

export const useLayout = (): IContextProps => useContext(LayoutContext)

const LayoutProvider = ({ children }: AppProviderProps) => {
  const [showHeader, setShowHeader] = useState<boolean>(true)
  const [showBack, setShowBack] = useState<boolean>(false)
  const [mapDrawingMode, setMapDrawingMode] = useState<'select' | 'drawing' | undefined>(undefined)
  const [showInstruction, setShowInstruction] = useState<boolean>(true)

  const activeRefs = useRef<RefObject<HTMLDivElement>[]>([]).current

  return (
    <LayoutContext.Provider
      value={{
        showHeader,
        setShowHeader,
        showBack,
        setShowBack,
        activeRefs,
        mapDrawingMode,
        setMapDrawingMode,
        showInstruction,
        setShowInstruction,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
