import { useRef, useState } from 'react'

export function useModal<T = boolean>() {
  const resolveFnRef = useRef<((value: T) => void) | null>(null)

  const [show, setShow] = useState<boolean>(false)

  const showModal = () => {
    setShow(true)
    return new Promise<T>(resolve => {
      resolveFnRef.current = resolve
    })
  }

  const closeModal = () => {
    setShow(false)
  }

  const confirm = (value: T) => {
    setShow(false)
    if (resolveFnRef.current) {
      resolveFnRef.current(value)
    }
  }

  return {
    show,
    showModal,
    closeModal,
    confirm,
  }
}
