import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQuery } from 'api/axiosBaseQuery'
import { MAX_PAGE_SIZE } from 'constant'
import {
  ActivityResponse,
  BaseSearchParams,
  Currency,
  FilterCategory,
  LocationResponse,
  RequestBookingBody,
  SearchParams,
  InventoryGroupResponse,
  MetaSearchResponse,
  InventoryGroupDetail,
  Vendor,
  SearchOffersParams,
  InventoryGroupSearchTopOffersResponse,
} from 'features/common/types'

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: axiosBaseQuery,
  tagTypes: [
    'Currencies',
    'Activities',
    'Cities',
    'Locations',
    'FilterCategory',
    'MetaSearch',
    'InventoryGroups',
    'InventoryGroupDetail',
    'RequestBooking',
    'Vendor',
    'TopOffers',
  ],
  endpoints: builder => ({
    getCurrencies: builder.query<Currency[], void>({
      query: () => ({
        url: '/currency',
        method: 'GET',
        params: { page_size: MAX_PAGE_SIZE },
      }),
      transformResponse: (response: { items: Currency[] }) => response.items,
      providesTags: ['Currencies'],
    }),
    getActivities: builder.query<
      ActivityResponse,
      {
        locationId: string | undefined
        lat: number | undefined
        lng: number | undefined
        radius: number | undefined
        polygons?: string | undefined
      }
    >({
      query: params => ({
        url: '/activity/all',
        method: 'GET',
        params: {
          location_id: params.locationId,
          pin_lat: params.lat,
          pin_lng: params.lng,
          radius: params.radius,
          polygons: params.polygons,
        },
      }),
      providesTags: ['Activities'],
    }),
    getLocations: builder.query<
      LocationResponse,
      {
        q?: string
        page?: number
        page_size?: number
        lat?: number
        lng?: number
      }
    >({
      query: params => ({
        url: '/location/full-search',
        method: 'GET',
        params,
      }),
      providesTags: ['Locations'],
    }),
    getFilterCategory: builder.query<FilterCategory, BaseSearchParams>({
      query: params => ({
        url: '/inventory-group/search/categories',
        method: 'GET',
        params: {
          location_id: params.locationId,
          location: params.location,
          pin_lat: params.pin_lat,
          pin_lng: params.pin_lng,
          radius: params.radius,
          polygons: params.polygons,
          activity_ids: params.activityIds?.join(','),
          activities: params.activities?.join(','),
          types: params.types?.join(','),
          durations: params.durations?.join(','),
          min_price: params.min_price,
          max_price: params.max_price,
          currency: params.currency,
          tags: params.tags?.join(','),
          min_review_rating: params.min_review_rating,
          vendor_id: params.vendorId,
        },
      }),
      providesTags: ['FilterCategory'],
    }),
    getMetaSearch: builder.query<MetaSearchResponse, BaseSearchParams>({
      query: params => ({
        url: '/inventory-group/search/meta',
        method: 'GET',
        params: {
          pin_lat: params.pin_lat,
          pin_lng: params.pin_lng,
          radius: params.radius,
          location_id: params.locationId,
          polygons: params.polygons,
          activity_ids: params.activityIds?.join(','),
          location: params.location,
          activities: params.activities?.join(','),
          tags: params.tags?.join(','),
        },
      }),
      providesTags: ['MetaSearch'],
    }),
    getInventoryGroups: builder.query<InventoryGroupResponse, SearchParams>({
      query: data => {
        const params = {
          location_id: data.locationId,
          location: data.location,
          pin_lat: data.pin_lat,
          pin_lng: data.pin_lng,
          radius: data.radius,
          polygons: data.polygons,
          activity_ids: data.activityIds?.join(','),
          activities: data.activities?.join(','),
          currency: data.currency,
          types: data.types?.join(','),
          durations: data.durations?.join(','),
          page: data.page,
          page_size: data.pageSize,
          ne_lat: data.neLat,
          ne_lng: data.neLng,
          sw_lat: data.swLat,
          sw_lng: data.swLng,
          sort_by: data.sort_by,
          min_price: data.min_price,
          max_price: data.max_price,
          min_review_rating: data.min_review_rating,
          vendor_id: data.vendorId,
          tags: data.tags?.join(','),
          from_view: data.from_view,
        }
        return {
          url: '/inventory-group/search',
          method: 'GET',
          params: Object.fromEntries(Object.entries(params).filter(([, value]) => value)),
        }
      },
      providesTags: ['InventoryGroups'],
    }),
    getInventoryGroupDetail: builder.query<InventoryGroupDetail, { id: string; currency: string }>({
      query: params => ({
        url: `/inventory-group/${params.id}`,
        method: 'GET',
        params: {
          currency: params.currency,
        },
      }),
      providesTags: ['InventoryGroupDetail'],
    }),
    requestBooking: builder.mutation<
      { payment_link: string; booking_id: string },
      RequestBookingBody
    >({
      query: body => ({
        url: '/booking',
        method: 'POST',
        params: { locale: body.locale, currency: body.currency, app_version: 'v2' },
        data: body.data,
      }),
      invalidatesTags: [{ type: 'RequestBooking', id: 'RequestBooking' }],
    }),
    getVendor: builder.query<Vendor, string>({
      query: vendorId => ({
        url: `/vendor/${vendorId}`,
        method: 'GET',
      }),
      providesTags: ['Vendor'],
    }),
    getTopOffers: builder.query<InventoryGroupSearchTopOffersResponse, SearchOffersParams>({
      query: data => {
        const params = {
          location_id: data.locationId,
          location: data.location,
          pin_lat: data.pin_lat,
          pin_lng: data.pin_lng,
          radius: data.radius,
          activity_ids: data.activityIds?.join(','),
          activities: data.activities?.join(','),
          currency: data.currency,
          types: data.types?.join(','),
          durations: data.durations?.join(','),
          ne_lat: data.neLat,
          ne_lng: data.neLng,
          sw_lat: data.swLat,
          sw_lng: data.swLng,
          sort_by: data.sort_by,
          min_price: data.min_price,
          max_price: data.max_price,
          min_review_rating: data.min_review_rating,
          vendor_id: data.vendorId,
        }

        return {
          url: '/inventory-group/search/top-offers',
          method: 'GET',
          params: Object.fromEntries(Object.entries(params).filter(([, value]) => value)),
        }
      },
      providesTags: ['TopOffers'],
    }),
  }),
})

export const {
  useGetCurrenciesQuery,
  useGetActivitiesQuery,
  useGetLocationsQuery,
  useGetMetaSearchQuery,
  useGetFilterCategoryQuery,
  useGetInventoryGroupsQuery,
  useGetVendorQuery,
  useGetInventoryGroupDetailQuery,
  useRequestBookingMutation,
  useGetTopOffersQuery,
} = searchApi
