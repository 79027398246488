import { ProgressPrimitive } from '@watersport/ui-react-next'
import React, { useEffect, useState } from 'react'

export interface LoadingProps {
  label: string
}

const Loading: React.FC<LoadingProps> = ({ label }) => {
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 99) {
        setProgress(progress + 1)
      }
    }, 5)
    return () => clearTimeout(timer)
  }, [progress])

  return (
    <div className="flex flex-col h-screen overflow-auto bg-white rounded-t-3xl items-center justify-center px-10">
      <p className="text-sm font-semibold text-center mb-4">{label}</p>
      <ProgressPrimitive.Progress
        value={progress}
        className="bg-gray-300 h-1"
        primaryColor="bg-blue-700"
      />
    </div>
  )
}

export default Loading
