import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'

import { API_URL } from 'constant'

type Error = {
  status: string
  statusText: string
  data: number
  message: string
}

export const makeApi = (baseURL: string) => {
  const api = axios.create({ baseURL })

  api.defaults.headers.post['Content-Type'] = 'application/json'
  api.defaults.headers.put['Content-Type'] = 'application/json'
  api.defaults.headers.delete['Content-Type'] = 'application/json'

  api.interceptors.request.use(
    config => config,
    error => Promise.reject(error),
  )

  api.interceptors.response.use(
    (response: AxiosResponse) => response.data, // return data object
    (error: AxiosError) => {
      let message = error.response?.statusText
      const object =
        (error.response?.data as { [key: string]: string | string[] | object | object[] }) || {}
      const keys = Object.keys(object)
      if (keys.length > 0) {
        const value = object[keys[0]]
        if (typeof value === 'string') message = `${message}: ${value}`
        if (Array.isArray(value) && value.length > 0) message = `${message}: ${value[0].toString()}`
      }
      const err = {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        message,
      }

      return Promise.reject(err)
    },
  )

  return api
}

const mainApi = makeApi(API_URL)

const axiosQuery =
  (): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    headers?: AxiosRequestConfig['headers']
  }> =>
  async requestOpts => {
    try {
      const result = await mainApi({
        ...requestOpts,
      })

      return { data: { ...result } }
    } catch (axiosError) {
      const err = axiosError as Error
      return { error: { status: err.status, data: err.data } }
    }
  }

export const axiosBaseQuery = axiosQuery()
